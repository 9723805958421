<template>
  <div>
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/analysis/exam' }">考试分析</el-breadcrumb-item>
        <el-breadcrumb-item>考试题目</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px">
      <div class="top">
        <Input class="search-bar" search placeholder="请输入关键字" @on-search="search" v-model="keyword" />
      </div>
      <Table :height="th" :data="examData" :columns="examColumn" :loading="loading">
        <template slot-scope="{ row, index }" slot="stem">
          <Tooltip placement="top" transfer style="width: 100%" max-width="100%">
            <div slot="content">
              <div v-html="row.stem"></div>
            </div>
            <pre v-html="row.stem" class="nowrap" style="overflow: hidden;max-width: 100%"></pre>
          </Tooltip>
        </template>
        <template slot-scope="{ row, index }" slot="diff">
          <span>{{ difficulty[row.difficulty].label }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="type">
          <span>{{ questionType[row.questionType].label }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="correct">
          <span>{{ Math.round(row.rightRate * 100) || 0 }}%</span>
        </template>
      </Table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        @on-change="changePage"
        @on-page-size-change="changeSize"
        :total="total"
        show-sizer
        show-elevator
        show-total
      ></Page>
    </div>
  </div>
</template>

<script>
import { difficulty } from '@util/difficulty'
import { questionType } from '@util/questionType'
import questionRepo from '@components/common/old/questionOld'

export default {
  name: 'ExamQuestions',
  data() {
    return {
      difficulty: difficulty,
      questionType: questionType,
      keyword: '',
      loading: false,
      page: 0,
      size: 10,
      th: document.body.clientHeight - 185,
      total: 0,
      examData: [],
      examColumn: [
        {
          title: '题干',
          slot: 'stem'
        },
        {
          title: '难度',
          slot: 'diff',
          width: 125
        },
        {
          title: '题型',
          slot: 'type',
          width: 125
        },
        {
          title: '考试题目正确率',
          slot: 'correct',
          width: 125
        },
        {
          title: '做题人数',
          key: 'doCount',
          width: 150
        },
        {
          title: '错误人数',
          key: 'wrongCount',
          width: 150
        }
      ]
    }
  },
  created() {
    this.initExam()
  },
  methods: {
    initExam() {
      this.loading = true
      questionRepo
        .getExamQ(this.$handleParams('examAnalysisQuestionsData').examId, this.page, this.size, this.keyword)
        .then(res => {
          this.total = res.res.total
          this.examData = res.res.data.map(item => {
            item.stem = this.cleanWord(item.stem)
            return item
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.initExam()
    },
    changeSize(val) {
      this.size = val
      this.initExam()
    },
    search() {
      this.page = 0
      this.size = 10
      this.initExam()
    }
  }
}
</script>

<style scoped lang="less">
.top {
  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 5px rgba(7, 27, 41, 0.1);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  .search-bar {
    width: 300px;
    ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }
  }
}
</style>
